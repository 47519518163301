package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionOverviewContentProps.component1(): PartyDetails = party

public operator fun ContributionOverviewContentProps.component2(): List<Contribution> =
    contributions

public operator fun ContributionOverviewContentProps.component3(): List<Player> = contributors

public operator fun ContributionOverviewContentProps.component4(): Key? = key

public fun ElementType<ContributionOverviewContentProps>.create(
  party: PartyDetails,
  contributions: List<Contribution>,
  contributors: List<Player>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.contributions = contributions
  this.contributors = contributors
  key?.let { this.key = it }
         }
}
