package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.action.stats.PairReport
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairReportViewProps.component1(): PairReport = pairReport

public operator fun PairReportViewProps.component2(): Key? = key

public fun ElementType<PairReportViewProps>.create(pairReport: PairReport, key: Key? = null):
    ReactNode {
   return create {
         this.pairReport = pairReport
  key?.let { this.key = it }
         }
}
