package com.zegreatrob.coupling.client.components.pairassignments.spin

import com.zegreatrob.coupling.client.components.spin.RosteredPairAssignments
import com.zegreatrob.coupling.model.party.PartyDetails
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun SpinAnimationPanelProps.component1(): PartyDetails = party

public operator fun SpinAnimationPanelProps.component2(): RosteredPairAssignments =
    rosteredPairAssignments

public operator fun SpinAnimationPanelProps.component3(): SpinAnimationState = state

public operator fun SpinAnimationPanelProps.component4(): Key? = key

public fun ElementType<SpinAnimationPanelProps>.create(
  party: PartyDetails,
  rosteredPairAssignments: RosteredPairAssignments,
  state: SpinAnimationState,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.rosteredPairAssignments = rosteredPairAssignments
  this.state = state
  key?.let { this.key = it }
         }
}
