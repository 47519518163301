package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionCardHeader(
  contribution: Contribution,
  contributors: List<Player>,
  key: Key? = null,
) {
    val component = (ContributionCardHeader.unsafeCast<FC<ContributionCardHeaderProps>>())
    component {
         this.contribution = contribution
  this.contributors = contributors
  key?.let { this.key = it }
         }
}
