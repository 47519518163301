package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.Iterable
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun TiltedPlayerListProps.component1(): Iterable<Player> = playerList

public operator fun TiltedPlayerListProps.component2(): Int? = size

public operator fun TiltedPlayerListProps.component3(): Key? = key

public fun ElementType<TiltedPlayerListProps>.create(
  playerList: Iterable<Player>,
  size: Int? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.playerList = playerList
  size?.let { this.size = it }
  key?.let { this.key = it }
         }
}
