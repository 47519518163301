package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.action.pin.DeletePinCommand
import com.zegreatrob.coupling.action.pin.SavePinCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <D> PinConfigProps<D>.component1(): PartyDetails where D :
    DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher = party

public operator fun <D> PinConfigProps<D>.component2(): Boost? where D :
    DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher = boost

public operator fun <D> PinConfigProps<D>.component3(): Pin where D : DeletePinCommand.Dispatcher, D
    : SavePinCommand.Dispatcher = pin

public operator fun <D> PinConfigProps<D>.component4(): List<Pin> where D :
    DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher = pinList

public operator fun <D> PinConfigProps<D>.component5(): () -> Unit where D :
    DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher = reload

public operator fun <D> PinConfigProps<D>.component6(): DispatchFunc<D> where D :
    DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher = dispatchFunc

public operator fun <D> PinConfigProps<D>.component7(): Key? where D : DeletePinCommand.Dispatcher,
    D : SavePinCommand.Dispatcher = key

public fun <D> ElementType<PinConfigProps<D>>.create(
  party: PartyDetails,
  boost: Boost? = null,
  pin: Pin,
  pinList: List<Pin>,
  reload: () -> Unit,
  dispatchFunc: DispatchFunc<D>,
  key: Key? = null,
): ReactNode where D : DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.pin = pin
  this.pinList = pinList
  this.reload = reload
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
