package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.client.components.`external`.reactdndhtml5backend.ReactDndHtml5BackendModule
import com.zegreatrob.react.dataloader.DataLoadState
import kotlin.Unit
import react.ChildrenBuilder
import react.ElementType
import react.Fragment
import react.Key
import react.ReactNode
import react.create

public operator fun DndProviderLoadingProps.component1(): Key? = key

public operator fun DndProviderLoadingProps.component2(): DataLoadState<ReactDndHtml5BackendModule?>
    = value

public operator fun DndProviderLoadingProps.component3(): ReactNode? = children

public fun ElementType<DndProviderLoadingProps>.create(
  key: Key? = null,
  `value`: DataLoadState<ReactDndHtml5BackendModule?>,
  children: ChildrenBuilder.() -> Unit = {},
): ReactNode {
   return create {
         key?.let { this.key = it }
  this.value = value
  Fragment { children() }

         }
}
