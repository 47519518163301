package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.secret.CreateSecretCommand
import com.zegreatrob.coupling.action.secret.DeleteSecretCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.party.Secret
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <D> PartySecretsLayoutProps<D>.component1(): PartyDetails where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = partyDetails

public operator fun <D> PartySecretsLayoutProps<D>.component2(): List<Secret> where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = secrets

public operator fun <D> PartySecretsLayoutProps<D>.component3(): Boost? where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = boost

public operator fun <D> PartySecretsLayoutProps<D>.component4(): DispatchFunc<D> where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = dispatcher

public operator fun <D> PartySecretsLayoutProps<D>.component5(): () -> Unit where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = reload

public operator fun <D> PartySecretsLayoutProps<D>.component6(): Key? where D :
    DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher = key

public fun <D> ElementType<PartySecretsLayoutProps<D>>.create(
  partyDetails: PartyDetails,
  secrets: List<Secret>,
  boost: Boost? = null,
  dispatcher: DispatchFunc<D>,
  reload: () -> Unit,
  key: Key? = null,
): ReactNode where D : DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher {
   return create {
         this.partyDetails = partyDetails
  this.secrets = secrets
  boost?.let { this.boost = it }
  this.dispatcher = dispatcher
  this.reload = reload
  key?.let { this.key = it }
         }
}
