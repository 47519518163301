package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionCardProps.component1(): Contribution = contribution

public operator fun ContributionCardProps.component2(): List<Player> = contributors

public operator fun ContributionCardProps.component3(): Key? = key

public fun ElementType<ContributionCardProps>.create(
  contribution: Contribution,
  contributors: List<Player>,
  key: Key? = null,
): ReactNode {
   return create {
         this.contribution = contribution
  this.contributors = contributors
  key?.let { this.key = it }
         }
}
