package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.ContributionReport
import com.zegreatrob.coupling.model.pairassignmentdocument.CouplingPair
import kotlin.Pair
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairFrequencyControlsProps.component1():
    List<Pair<CouplingPair, ContributionReport>> = pairsContributions

public operator fun PairFrequencyControlsProps.component2(): (VisualizationContext) -> ReactNode =
    view

public operator fun PairFrequencyControlsProps.component3(): GqlContributionWindow = window

public operator fun PairFrequencyControlsProps.component4(): (GqlContributionWindow) -> Unit =
    setWindow

public operator fun PairFrequencyControlsProps.component5(): Key? = key

public fun ElementType<PairFrequencyControlsProps>.create(
  pairsContributions: List<Pair<CouplingPair, ContributionReport>>,
  view: (VisualizationContext) -> ReactNode,
  window: GqlContributionWindow,
  setWindow: (GqlContributionWindow) -> Unit,
  key: Key? = null,
): ReactNode {
   return create {
         this.pairsContributions = pairsContributions
  this.view = view
  this.window = window
  this.setWindow = setWindow
  key?.let { this.key = it }
         }
}
