package com.zegreatrob.coupling.client.user

import com.zegreatrob.coupling.action.ApplyBoostCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.user.SubscriptionDetails
import com.zegreatrob.coupling.model.user.UserDetails
import com.zegreatrob.coupling.sdk.gql.GraphQuery
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <D> UserConfigProps<D>.component1(): UserDetails? where D :
    GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher = user

public operator fun <D> UserConfigProps<D>.component2(): SubscriptionDetails? where D :
    GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher = subscription

public operator fun <D> UserConfigProps<D>.component3(): List<PartyDetails> where D :
    GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher = partyList

public operator fun <D> UserConfigProps<D>.component4(): DispatchFunc<D> where D :
    GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher = dispatcher

public operator fun <D> UserConfigProps<D>.component5(): String where D : GraphQuery.Dispatcher, D :
    ApplyBoostCommand.Dispatcher = stripeAdminCode

public operator fun <D> UserConfigProps<D>.component6(): String where D : GraphQuery.Dispatcher, D :
    ApplyBoostCommand.Dispatcher = stripePurchaseCode

public operator fun <D> UserConfigProps<D>.component7(): Boost? where D : GraphQuery.Dispatcher, D :
    ApplyBoostCommand.Dispatcher = boost

public operator fun <D> UserConfigProps<D>.component8(): () -> Unit where D : GraphQuery.Dispatcher,
    D : ApplyBoostCommand.Dispatcher = reload

public operator fun <D> UserConfigProps<D>.component9(): Key? where D : GraphQuery.Dispatcher, D :
    ApplyBoostCommand.Dispatcher = key

public fun <D> ElementType<UserConfigProps<D>>.create(
  user: UserDetails? = null,
  subscription: SubscriptionDetails? = null,
  partyList: List<PartyDetails>,
  dispatcher: DispatchFunc<D>,
  stripeAdminCode: String,
  stripePurchaseCode: String,
  boost: Boost? = null,
  reload: () -> Unit,
  key: Key? = null,
): ReactNode where D : GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher {
   return create {
         user?.let { this.user = it }
  subscription?.let { this.subscription = it }
  this.partyList = partyList
  this.dispatcher = dispatcher
  this.stripeAdminCode = stripeAdminCode
  this.stripePurchaseCode = stripePurchaseCode
  boost?.let { this.boost = it }
  this.reload = reload
  key?.let { this.key = it }
         }
}
