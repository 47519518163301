package com.zegreatrob.coupling.client.components.slack

import com.zegreatrob.coupling.action.party.SaveSlackIntegrationCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.String
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun SlackConnectPageContentProps.component1(): List<PartyDetails> = parties

public operator fun SlackConnectPageContentProps.component2(): String = slackTeam

public operator fun SlackConnectPageContentProps.component3(): String = slackChannel

public operator fun SlackConnectPageContentProps.component4():
    DispatchFunc<SaveSlackIntegrationCommand.Dispatcher> = dispatchFunc

public operator fun SlackConnectPageContentProps.component5(): Key? = key

public fun ElementType<SlackConnectPageContentProps>.create(
  parties: List<PartyDetails>,
  slackTeam: String,
  slackChannel: String,
  dispatchFunc: DispatchFunc<SaveSlackIntegrationCommand.Dispatcher>,
  key: Key? = null,
): ReactNode {
   return create {
         this.parties = parties
  this.slackTeam = slackTeam
  this.slackChannel = slackChannel
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
