package com.zegreatrob.coupling.client.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.action.pairassignmentdocument.SavePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <D> SocketedPairAssignmentsProps<D>.component1(): PartyDetails where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = party

public operator fun <D> SocketedPairAssignmentsProps<D>.component2(): Boost? where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = boost

public operator fun <D> SocketedPairAssignmentsProps<D>.component3(): List<Player> where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = players

public operator fun <D> SocketedPairAssignmentsProps<D>.component4(): PairAssignmentDocument? where
    D : SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher =
    pairAssignments

public operator fun <D> SocketedPairAssignmentsProps<D>.component5(): Controls<D> where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = controls

public operator fun <D> SocketedPairAssignmentsProps<D>.component6(): Boolean where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = allowSave

public operator fun <D> SocketedPairAssignmentsProps<D>.component7(): Key? where D :
    SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher = key

public fun <D> ElementType<SocketedPairAssignmentsProps<D>>.create(
  party: PartyDetails,
  boost: Boost? = null,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument? = null,
  controls: Controls<D>,
  allowSave: Boolean,
  key: Key? = null,
): ReactNode where D : SavePairAssignmentsCommand.Dispatcher, D :
    DeletePairAssignmentsCommand.Dispatcher {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.players = players
  pairAssignments?.let { this.pairAssignments = it }
  this.controls = controls
  this.allowSave = allowSave
  key?.let { this.key = it }
         }
}
