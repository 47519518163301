package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.action.player.DeletePlayerCommand
import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.client.components.`external`.w3c.WindowFunctions
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <P> PlayerConfigProps<P>.component1(): PartyDetails where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = party

public operator fun <P> PlayerConfigProps<P>.component2(): Boost? where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = boost

public operator fun <P> PlayerConfigProps<P>.component3(): Player where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = player

public operator fun <P> PlayerConfigProps<P>.component4(): List<Player> where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = players

public operator fun <P> PlayerConfigProps<P>.component5(): () -> Unit where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = reload

public operator fun <P> PlayerConfigProps<P>.component6(): DispatchFunc<P> where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = dispatchFunc

public operator fun <P> PlayerConfigProps<P>.component7(): WindowFunctions? where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = windowFuncs

public operator fun <P> PlayerConfigProps<P>.component8(): Key? where P :
    SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher = key

public fun <P> ElementType<PlayerConfigProps<P>>.create(
  party: PartyDetails,
  boost: Boost? = null,
  player: Player,
  players: List<Player>,
  reload: () -> Unit,
  dispatchFunc: DispatchFunc<P>,
  windowFuncs: WindowFunctions? = null,
  key: Key? = null,
): ReactNode where P : SavePlayerCommand.Dispatcher, P : DeletePlayerCommand.Dispatcher {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.player = player
  this.players = players
  this.reload = reload
  this.dispatchFunc = dispatchFunc
  windowFuncs?.let { this.windowFuncs = it }
  key?.let { this.key = it }
         }
}
