package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.party.DeletePartyCommand
import com.zegreatrob.coupling.action.party.SavePartyCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <D> PartyConfigProps<D>.component1(): PartyDetails where D :
    SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher = party

public operator fun <D> PartyConfigProps<D>.component2(): Boost? where D :
    SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher = boost

public operator fun <D> PartyConfigProps<D>.component3(): DispatchFunc<D> where D :
    SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher = dispatchFunc

public operator fun <D> PartyConfigProps<D>.component4(): Key? where D :
    SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher = key

public fun <D> ElementType<PartyConfigProps<D>>.create(
  party: PartyDetails,
  boost: Boost? = null,
  dispatchFunc: DispatchFunc<D>,
  key: Key? = null,
): ReactNode where D : SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
