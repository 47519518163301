package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.party.PartyDetails
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PrepareToSpinButtonProps.component1(): PartyDetails = party

public operator fun PrepareToSpinButtonProps.component2(): Key? = key

public fun ElementType<PrepareToSpinButtonProps>.create(party: PartyDetails, key: Key? = null):
    ReactNode {
   return create {
         this.party = party
  key?.let { this.key = it }
         }
}
