package com.zegreatrob.coupling.client.components.`external`.auth0.react

import js.objects.jso
import kotlin.String

public operator fun Auth0LogoutStructure.component1(): String? = clientId

public operator fun Auth0LogoutStructure.component2(): Auth0LogoutParams? = logoutParams

public fun Auth0LogoutStructure(clientId: String? = null, logoutParams: Auth0LogoutParams? = null):
    Auth0LogoutStructure {
    return jso<Auth0LogoutStructure> {
         clientId?.let { this.clientId = it }
  logoutParams?.let { this.logoutParams = it }
         }
}
