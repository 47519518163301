package com.zegreatrob.coupling.client.party

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyListProps.component1(): List<PartyDetails> = parties

public operator fun PartyListProps.component2(): Key? = key

public fun ElementType<PartyListProps>.create(parties: List<PartyDetails>, key: Key? = null):
    ReactNode {
   return create {
         this.parties = parties
  key?.let { this.key = it }
         }
}
