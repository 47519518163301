package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientConfig
import kotlin.Boolean
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.CouplingRouter(
  animationsDisabled: Boolean,
  config: ClientConfig,
  key: Key? = null,
) {
    val component = (CouplingRouter.unsafeCast<FC<CouplingRouterProps>>())
    component {
         this.animationsDisabled = animationsDisabled
  this.config = config
  key?.let { this.key = it }
         }
}
