package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.Iterable
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.TiltedPlayerList(
  playerList: Iterable<Player>,
  size: Int? = null,
  key: Key? = null,
) {
    val component = (TiltedPlayerList.unsafeCast<FC<TiltedPlayerListProps>>())
    component {
         this.playerList = playerList
  size?.let { this.size = it }
  key?.let { this.key = it }
         }
}
