package com.zegreatrob.coupling.client.components.spin

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Pair
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PrepareSpinContentProps.component1(): PartyDetails = party

public operator fun PrepareSpinContentProps.component2(): List<Pair<Player, Boolean>> =
    playerSelections

public operator fun PrepareSpinContentProps.component3(): List<Pin> = pins

public operator fun PrepareSpinContentProps.component4(): List<String> = pinSelections

public operator fun PrepareSpinContentProps.component5(): (List<Pair<Player, Boolean>>) -> Unit =
    setPlayerSelections

public operator fun PrepareSpinContentProps.component6(): (List<String>) -> Unit = setPinSelections

public operator fun PrepareSpinContentProps.component7(): (() -> Unit)? = onSpin

public operator fun PrepareSpinContentProps.component8(): Key? = key

public fun ElementType<PrepareSpinContentProps>.create(
  party: PartyDetails,
  playerSelections: List<Pair<Player, Boolean>>,
  pins: List<Pin>,
  pinSelections: List<String>,
  setPlayerSelections: (List<Pair<Player, Boolean>>) -> Unit,
  setPinSelections: (List<String>) -> Unit,
  onSpin: (() -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.playerSelections = playerSelections
  this.pins = pins
  this.pinSelections = pinSelections
  this.setPlayerSelections = setPlayerSelections
  this.setPinSelections = setPinSelections
  onSpin?.let { this.onSpin = it }
  key?.let { this.key = it }
         }
}
