package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionOverviewContent(
  party: PartyDetails,
  contributions: List<Contribution>,
  contributors: List<Player>,
  key: Key? = null,
) {
    val component = (ContributionOverviewContent.unsafeCast<FC<ContributionOverviewContentProps>>())
    component {
         this.party = party
  this.contributions = contributions
  this.contributors = contributors
  key?.let { this.key = it }
         }
}
