package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.action.stats.PairReport
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairReportTableProps.component1(): List<PairReport> = pairReports

public operator fun PairReportTableProps.component2(): Key? = key

public fun ElementType<PairReportTableProps>.create(pairReports: List<PairReport>, key: Key? =
    null): ReactNode {
   return create {
         this.pairReports = pairReports
  key?.let { this.key = it }
         }
}
