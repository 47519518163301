package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.ContributionReport
import com.zegreatrob.coupling.model.pairassignmentdocument.CouplingPair
import kotlin.Pair
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairFrequencyLineGraphProps.component1():
    List<Pair<CouplingPair, ContributionReport>> = data

public operator fun PairFrequencyLineGraphProps.component2(): GqlContributionWindow = window

public operator fun PairFrequencyLineGraphProps.component3(): Key? = key

public fun ElementType<PairFrequencyLineGraphProps>.create(
  `data`: List<Pair<CouplingPair, ContributionReport>>,
  window: GqlContributionWindow,
  key: Key? = null,
): ReactNode {
   return create {
         this.data = data
  this.window = window
  key?.let { this.key = it }
         }
}
