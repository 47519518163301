package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionListContentProps.component1(): PartyDetails = party

public operator fun ContributionListContentProps.component2(): List<Contribution> = contributions

public operator fun ContributionListContentProps.component3(): List<Player> = contributors

public operator fun ContributionListContentProps.component4(): GqlContributionWindow = window

public operator fun ContributionListContentProps.component5(): (GqlContributionWindow) -> Unit =
    setWindow

public operator fun ContributionListContentProps.component6(): Key? = key

public fun ElementType<ContributionListContentProps>.create(
  party: PartyDetails,
  contributions: List<Contribution>,
  contributors: List<Player>,
  window: GqlContributionWindow,
  setWindow: (GqlContributionWindow) -> Unit,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.contributions = contributions
  this.contributors = contributors
  this.window = window
  this.setWindow = setWindow
  key?.let { this.key = it }
         }
}
