package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PairAssignmentsHeader(pairAssignments: PairAssignmentDocument, key: Key?
    = null) {
    val component = (PairAssignmentsHeader.unsafeCast<FC<PairAssignmentsHeaderProps>>())
    component {
         this.pairAssignments = pairAssignments
  key?.let { this.key = it }
         }
}
